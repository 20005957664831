import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { EGDSLayoutFlex } from '@egds/react-core/layout-flex';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSText } from '@egds/react-core/text';
import { useExperiment } from '@shared-ui/experiment-context';
import { PropertyListingHeaderMessages } from '@shared-ui/lodging-property-listing';
import { SortAndFiltersViewType } from '@shared-ui/retail-sort-and-filter-lodging';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
import { ViewLarge, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { getSharedUIListingsInputsWithSearchId, hasSearchCriteriaChanged } from 'stores/adapters';
import { SWPToggle } from 'components/common/swp-toggle';
import { GridViewToggle } from 'components/search/grid-view/toggle';
import { UniversalSortFilterWrapper } from '../sort-filter/universal-sort-filter';
import { PointsToggleAndSortOrder } from './points-toggle-and-sort-order';
import { ResultsSubHeaderWrapper } from './results-sub-header-wrapper';
export const ResultsSubHeader = inject('context', 'searchCriteria', 'searchResults', 'experiment', 'multiItem', 'analyticsSearch', 'pageLayout')(
// TODO: Cleanup this eslint disable when we refactor to remove the shopping-pwa implementation of this subheader
// eslint-disable-next-line complexity
observer(props => {
  var _a, _b;
  const {
    resultMessages,
    isLoading,
    l10n,
    loyaltyMessagingModel,
    searchCriteria,
    searchResults,
    isQuickAccessFiltersActive,
    context,
    experiment,
    multiItem,
    analyticsSearch,
    pageLayout,
    spacingOptions
  } = props;
  const listingsQueryInputs = useProductShoppingCriteria();
  const isError = Boolean(searchResults === null || searchResults === void 0 ? void 0 : searchResults.isError);
  const multiItemId = searchCriteria && searchCriteria.misId;
  const lob = multiItemId ? 'multiItem' : 'hotels';
  const localizedText = l10n.formatText(`${lob}.searchResults.loadingMessage.text`);
  const location = useLocation();
  if (!context || !searchCriteria || !searchResults || !analyticsSearch) {
    return null;
  }
  const usePropertyListingQuery = !(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath());
  const {
    exposure: gridViewExperimentExposure
  } = useExperiment('SRP_Grid_view_web', false);
  const searchInputs = usePropertyListingQuery && listingsQueryInputs ? getSharedUIListingsInputsWithSearchId(listingsQueryInputs, context, analyticsSearch, location, pageLayout, searchCriteria, experiment) : undefined;
  const criteriaChanged = Boolean(usePropertyListingQuery && hasSearchCriteriaChanged(context, searchCriteria, searchResults));
  const isLoyaltyPWPToggleEnabled = !(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) && ((_b = (_a = experiment === null || experiment === void 0 ? void 0 : experiment.exposures) === null || _a === void 0 ? void 0 : _a.ETS_POINTS_TOGGLE_UI_DISPLAY) === null || _b === void 0 ? void 0 : _b.bucket) === 1;
  const shopWithPointsToggleJustifyContent = isLoyaltyPWPToggleEnabled ? 'end' : 'start';
  const justifySelf = isLoyaltyPWPToggleEnabled ? 'end' : undefined;
  const colEnd = isLoyaltyPWPToggleEnabled ? 9 : 0;
  const universalSortFilter = !isQuickAccessFiltersActive && React.createElement(EGDSLayoutGridItem, {
    colSpan: 3
  }, React.createElement("div", null, React.createElement(EGDSLayoutGrid, {
    columns: 1
  }, React.createElement(EGDSLayoutGridItem, null, React.createElement(UniversalSortFilterWrapper, {
    view: SortAndFiltersViewType.SORT
  })), React.createElement(EGDSLayoutGridItem, {
    alignSelf: 'end'
  }, React.createElement(EGDSSpacing, {
    padding: {
      blockstart: 'two'
    }
  }, React.createElement("div", null, React.createElement(GridViewToggle, {
    alignment: isLoyaltyPWPToggleEnabled ? 'start' : 'end'
  })))))));
  const pointsToggleAndSortOrderProps = {
    analyticsSearch,
    justifySelf,
    colEnd,
    isError,
    usePropertyListingQuery,
    localizedText,
    isLoyaltyPWPToggleEnabled,
    searchInputs,
    resultMessages,
    isLoading,
    loyaltyMessagingModel,
    searchCriteria,
    criteriaChanged
  };
  return React.createElement(EGDSSpacing, Object.assign({}, spacingOptions), React.createElement("header", null, React.createElement(Viewport, null, React.createElement(ViewSmall, null, React.createElement(React.Fragment, null, React.createElement(EGDSLayoutGrid, {
    columns: (gridViewExperimentExposure === null || gridViewExperimentExposure === void 0 ? void 0 : gridViewExperimentExposure.bucket) ? 2 : 1
  }, React.createElement(EGDSLayoutGridItem, null, !isError && React.createElement(React.Fragment, null, usePropertyListingQuery ? React.createElement(ResultsSubHeaderWrapper, {
    analyticsSearch: analyticsSearch,
    searchInputs: searchInputs,
    searchResults: searchResults,
    localizedText: localizedText,
    criteriaChanged: criteriaChanged,
    switchPositionOfToggleOnEPS: isLoyaltyPWPToggleEnabled
  }) : React.createElement(React.Fragment, null, loyaltyMessagingModel && React.createElement(EGDSLayoutFlex, {
    justifyContent: shopWithPointsToggleJustifyContent
  }, React.createElement(SWPToggle, {
    model: loyaltyMessagingModel,
    searchCriteria: searchCriteria,
    switchPositionOfToggleOnEPS: isLoyaltyPWPToggleEnabled
  })), React.createElement(HeaderMessages, {
    loading: isLoading,
    localizedText: localizedText,
    resultMessages: resultMessages
  })))), React.createElement(EGDSLayoutGridItem, null, React.createElement(GridViewToggle, null))))), React.createElement(ViewLarge, null, React.createElement(EGDSLayoutGrid, {
    columns: 8
  }, isLoyaltyPWPToggleEnabled ? React.createElement(React.Fragment, null, universalSortFilter, React.createElement(PointsToggleAndSortOrder, Object.assign({}, pointsToggleAndSortOrderProps, {
    searchResults: searchResults
  }))) : React.createElement(React.Fragment, null, React.createElement(PointsToggleAndSortOrder, Object.assign({}, pointsToggleAndSortOrderProps, {
    searchResults: searchResults
  })), universalSortFilter))))));
}));
ResultsSubHeader.displayName = 'ResultsSubHeader';
export const HeaderMessages = ({
  loading,
  localizedText,
  resultMessages
}) => {
  if (loading) {
    return React.createElement(EGDSSpacing, {
      padding: {
        blockend: 'two'
      }
    }, React.createElement(EGDSText, {
      inline: false,
      align: "left",
      theme: "default"
    }, localizedText));
  }
  if (!resultMessages || resultMessages.length === 0) {
    return null;
  }
  return React.createElement("div", {
    "data-stid": "results-header-messages"
  }, React.createElement(PropertyListingHeaderMessages, {
    messages: resultMessages,
    standardLinkFontWeight: "bold"
  }));
};
HeaderMessages.displayName = 'HeaderMessages';