/* eslint-disable max-lines */
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ClientLogger } from 'bernie-client';
import { updateSearch } from 'bernie-core';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { ErrorBoundary } from 'bernie-view';
import { EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSEGMapsQualifier } from '@egds/react-maps';
import { parse } from 'query-string';
import { changeView, View } from 'src/common/stores/contexts/viewTracker';
import { isMobile } from 'src/common/utils/is-mobile';
import { useExperiment } from '@shared-ui/experiment-context';
import { PropertySearchMapTrigger } from '@shared-ui/lodging-property-search';
import { SortAndFiltersViewType } from '@shared-ui/retail-sort-and-filter-lodging';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
import { UniversalSortFilterWrapper } from '../sort-filter/universal-sort-filter';
import { DynamicSearchMapWrapper } from './dynamic-search-map-wrapper';
const egMapConfiguration = {
  markers: {
    place: {
      zIndex: {
        unknown: 0
      }
    },
    property: {
      zIndex: {
        [`${EGDSEGMapsQualifier.NOT_CLUSTERED}`]: 0
      }
    }
  }
};
const logger = ClientLogger.getLoggerWithIdentifier('pwa.component.mappreview');
export const MapPreview = inject('experiment', 'multiItem', 'searchResults', 'searchCriteria', 'context', 'analyticsSearch')(observer(props => {
  const {
    context,
    dialogView,
    multiItem,
    tracker,
    viewTracker,
    searchInputs,
    searchResults,
    searchCriteria,
    bexQuickFiltersEnabled,
    analyticsSearch
  } = props;
  const location = useLocation();
  const history = useHistory();
  const {
    exposure: filterRemovalExperimentExposure,
    logExperiment: logFilterRemovalExperiment
  } = useExperiment('Filter_Removal_at_destination_level_based_on_supply_mweb_apps');
  const isPinned = React.useMemo(() => Boolean(new URLSearchParams(location.search).get('selected')), [location.search]);
  const {
    exposure: vrboMapButtonPinnedSrpContrastExposure,
    logExperiment: vrboMapButtonPinnedSrpContrastExperiment
  } = useExperiment('Vrbo_map_button_on_pinned_SRP_contrast_optimisation');
  React.useEffect(() => {
    if (isMobile(context) && isPinned) {
      vrboMapButtonPinnedSrpContrastExperiment(vrboMapButtonPinnedSrpContrastExposure);
    }
  }, [context, isPinned, vrboMapButtonPinnedSrpContrastExposure]);
  if (!context || !searchCriteria || !searchResults || !analyticsSearch) {
    logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'MAP_PREVIEW_MISSING_PROPS'));
    return null;
  }
  const onMapDialogClosed = () => {
    var _a;
    history && location && updateSearch({
      history,
      location,
      newParams: {
        pwaOverlay: ''
      }
    });
    changeView(tracker, viewTracker, View.LISTINGS, (_a = multiItem === null || multiItem === void 0 ? void 0 : multiItem.getPackageType()) !== null && _a !== void 0 ? _a : undefined);
  };
  const logExperimentExposure = () => {
    if (!(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath())) {
      logFilterRemovalExperiment(filterRemovalExperimentExposure);
    }
  };
  const productShoppingCriteriaInput = useProductShoppingCriteria();
  if (!(searchResults === null || searchResults === void 0 ? void 0 : searchResults.mapTrigger)) {
    logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, 'MAP_PREVIEW_MISSING_RESULTS'));
    return null;
  }
  const locationSearch = (location === null || location === void 0 ? void 0 : location.search) ? parse(location.search) : undefined;
  const inputs = searchInputs !== null && searchInputs !== void 0 ? searchInputs : {
    destination: {
      regionName: locationSearch === null || locationSearch === void 0 ? void 0 : locationSearch.destination,
      pinnedPropertyId: locationSearch === null || locationSearch === void 0 ? void 0 : locationSearch.selected
    },
    productShoppingCriteriaInput
  };
  const mapSortAndFilterViewType = bexQuickFiltersEnabled ? SortAndFiltersViewType.FILTER_BAR : SortAndFiltersViewType.FILTER_DIALOG;
  const showPrimaryButton = isMobile(context) && isPinned && vrboMapButtonPinnedSrpContrastExposure.bucket === 1;
  const dynamicSearchMapWrapper = React.createElement(DynamicSearchMapWrapper, {
    cfg: egMapConfiguration,
    clientPadding: "32px",
    onMapDialogClosed: onMapDialogClosed,
    filterComponent: React.createElement(EGDSLayoutFlexItem, {
      shrink: 0
    }, React.createElement(UniversalSortFilterWrapper, {
      view: mapSortAndFilterViewType,
      isMapView: true,
      onOpenDialog: logExperimentExposure
    }))
  });
  return React.createElement(ErrorBoundary, {
    label: "search.map.map-preview"
  }, React.createElement(PropertySearchMapTrigger, {
    inputs: inputs,
    dialogView: bexQuickFiltersEnabled ? SortAndFiltersViewType.FILTER_BAR : dialogView,
    externalData: searchResults === null || searchResults === void 0 ? void 0 : searchResults.mapTrigger,
    showPrimaryButton: showPrimaryButton
  }, dynamicSearchMapWrapper));
}));