import * as React from 'react';
import { ErrorBoundary } from 'bernie-view';
import { useStores } from 'src/common/stores/use-stores';
import { ProductTypeProvider, ProductType } from '@shared-ui/retail-product-type-providers';
import { ProductShoppingCriteriaProvider } from '@shared-ui/shopping-search-criteria-providers';
import { hasSearchCriteriaChanged } from 'stores/adapters';
import { ResultsAccessibilityHeading } from 'components/search/results-accessibility-heading';
import { SearchPageName } from 'constants/pages';
export const ResultsAccessibilityHeadingRegion = ({
  isHiddenFromScreenReader
}) => {
  const {
    context,
    searchResults,
    multiItem,
    searchCriteria,
    page
  } = useStores();
  const usePropertyListingQuery = !(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath()) && (page === null || page === void 0 ? void 0 : page.pageName) === SearchPageName.SA;
  const criteriaChanged = context && searchCriteria && searchResults ? hasSearchCriteriaChanged(context, searchCriteria, searchResults) : false;
  const resultsHeading = usePropertyListingQuery && React.createElement(ProductTypeProvider, {
    productType: ProductType.Lodging
  }, React.createElement(ProductShoppingCriteriaProvider, null, React.createElement(ErrorBoundary, {
    label: "hotel.masterPage.results.accessibility.heading"
  }, React.createElement(ResultsAccessibilityHeading, {
    criteriaChanged: criteriaChanged,
    isHiddenFromScreenReader: isHiddenFromScreenReader
  }))));
  return resultsHeading || null;
};
ResultsAccessibilityHeadingRegion.displayName = 'ResultsAccessibilityHeadingRegion';