/* eslint-disable max-lines */
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { mergeQueryString, updateSearch } from 'bernie-core';
import { ErrorBoundary } from 'bernie-view';
import { useStores } from 'src/common/stores/use-stores';
import { setCompressionBannerDismiss } from 'src/common/utils/compression-banner-utils';
import { useClickTracker } from '@shared-ui/clickstream-analytics-context';
import { useExperiment } from '@shared-ui/experiment-context';
import { ProductType, useProductType } from '@shared-ui/retail-product-type-providers';
import { LodgingSortAndFilters, SortAndFiltersCustomEventTypes, SortAndFiltersViewType } from '@shared-ui/retail-sort-and-filter-lodging';
import { useProductShoppingCriteria, useProductShoppingCriteriaDispatch } from '@shared-ui/shopping-search-criteria-providers';
import { buildUrlQueryString, getMultiTypeaheadProps, handleNeighborhoodPreviousFilter, removeSelectedFiltersQuery, setUrlParamsOnFilterChange } from './universal-sort-filter-utils';
import { useSortAndFilterInput } from './use-sort-and-filter-inputs';
const UniversalSortFilter = ({
  className = '',
  view,
  onOpenDialog,
  isMapView,
  filterBarSpacing,
  carouselEdgeItemsPadding
}) => {
  const {
    multiItem,
    searchResults,
    analyticsSearch,
    analytics,
    experiment
  } = useStores();
  const [urlQueryString, setUrlQueryString] = React.useState('');
  const {
    exposure: mesoSlotFilterAdRefreshExposure
  } = useExperiment('Meso_Slot_Filter_Ad_Refresh');
  const track = useClickTracker();
  const productShoppingCriteriaInput = useProductShoppingCriteria();
  const productShoppingCriteriaDispatch = useProductShoppingCriteriaDispatch();
  const productType = useProductType();
  const {
    inputs: lodgingInputs,
    filterSheetFooterInputs
  } = useSortAndFilterInput(urlQueryString);
  const {
    exposure: useStayOptionsGroupExposure
  } = useExperiment('Hotel_vs_VR_Contextualization');
  const history = useHistory();
  const location = useLocation();
  const isFiltersDialog = [SortAndFiltersViewType.SORT_AND_FILTERS, SortAndFiltersViewType.FILTER_DIALOG, SortAndFiltersViewType.FILTER_BAR].includes(view);
  const inputs = productType === ProductType.Lodging ? lodgingInputs : {
    destination: {},
    criteria: null,
    shoppingContext: {},
    productShoppingCriteriaInput
  };
  const {
    exposure: lodgingFacetCountExperimentExposure
  } = useExperiment('Filter_Facet_Counts');
  const isLodgingFacetCountExperimentVariant = [1, 2, 3].includes(lodgingFacetCountExperimentExposure.bucket);
  const isStayOptionVariant = [1, 2].includes(useStayOptionsGroupExposure === null || useStayOptionsGroupExposure === void 0 ? void 0 : useStayOptionsGroupExposure.bucket);
  const adjustedInputs = isLodgingFacetCountExperimentVariant || isStayOptionVariant ? filterSheetFooterInputs : inputs;
  const adjustedFilterSheetFooterInputs = isLodgingFacetCountExperimentVariant || isStayOptionVariant ? null : filterSheetFooterInputs;
  /**
   * When in Cruise LOB, override the default selection handler to support multiple typeahead selections
   */
  const multiTypeaheadProps = () => {
    if (productType !== ProductType.Cruise) {
      return {};
    }
    /**
     * TODO: Remove this section once Cruise filters get fully migrated to reducer
     */
    if (isFiltersDialog) {
      const existingUrlQueryString = getExistingQueryString();
      return {
        onSelectionChange() {
          // Do nothing. onSelectionChange handler needs to be present to override default behavior
        },
        onMultiSelectClose(item, filterId, selections) {
          const urlSearchParams = new URLSearchParams(existingUrlQueryString);
          const newSecondarySelections = [];
          selections === null || selections === void 0 ? void 0 : selections.reduce((accumulator, currentValue) => {
            const value = currentValue.data.value;
            if (value) {
              accumulator.push(value);
            }
            return accumulator;
          }, newSecondarySelections);
          urlSearchParams.set(filterId, newSecondarySelections.join('|'));
          captureFilterChange(urlSearchParams.toString());
        },
        onMultiSelectClearAll(filterId) {
          const urlSearchParams = new URLSearchParams(existingUrlQueryString);
          urlSearchParams.delete(filterId);
          captureFilterChange(urlSearchParams.toString());
        }
      };
    }
    return getMultiTypeaheadProps(productShoppingCriteriaDispatch);
  };
  /**
   *  isSearchResultsLoading only applies for Lodging, therefore, we check the productShoppingCriteriaInput is falsy
   */
  const isSearchResultsLoading = (searchResults === null || searchResults === void 0 ? void 0 : searchResults.isLoading) && !productShoppingCriteriaInput;
  const updateSearchWithQuery = query => {
    if (mesoSlotFilterAdRefreshExposure.bucket === 1) {
      document.dispatchEvent(new CustomEvent('mesoSlotFilterUpdate', {
        detail: {
          type: 'slotFilter',
          editType: 'update',
          filterData: {
            name: query
          }
        }
      }));
    }
    const queryCombiner = (_prevQuery, newQuery) => newQuery;
    analyticsSearch.withPublishingAnalyticsPayload(updateSearch, analytics, experiment)({
      history,
      location,
      newParams: setUrlParamsOnFilterChange(query),
      replace: false,
      queryCombiner
    });
  };
  const getExistingQueryString = () => isFiltersDialog && urlQueryString !== '' ? urlQueryString : history.location.search;
  const captureFilterChange = newQueryString => {
    // this component renders several different types of filter views - when the child filter view is a dialog
    // we simply capture filter field changes as local state in the form of a new query string
    // if it's not a dialog, we go ahead and update the page's query string with the new one we generated
    if (isFiltersDialog) {
      setUrlQueryString(newQueryString);
    } else {
      updateSearchWithQuery(newQueryString);
      window.scrollTo(0, 0);
      setCompressionBannerDismiss(false);
    }
  };
  const onFieldChange = (field, event) => {
    var _a;
    const isTrustedEvent = event && (event.isTrusted || ((_a = event.nativeEvent) === null || _a === void 0 ? void 0 : _a.isTrustedEvent));
    if (!handleNeighborhoodPreviousFilter(field, event, isTrustedEvent)) {
      const {
        name,
        value
      } = field;
      const newQueryString = buildUrlQueryString({
        name,
        value,
        existingUrlQueryString: getExistingQueryString(),
        stayOptionExperimentExposure: useStayOptionsGroupExposure,
        event
      });
      if (isTrustedEvent) {
        /* When a radio button type of filter (example- neighborhood filter) is changed,
         * it also triggers a change event (uncheck checkbox) for the neighborhood selection present in 'Your recent filters'
         * We want the radio button event handler to execute after the `Your recent filter` uncheck event handler */
        setTimeout(() => {
          captureFilterChange(newQueryString);
        });
      } else {
        captureFilterChange(newQueryString);
      }
    }
  };
  const onSubmitFilterSearch = (name, value, event) => {
    var _a;
    const isTrustedEvent = event && (event.isTrusted || ((_a = event.nativeEvent) === null || _a === void 0 ? void 0 : _a.isTrustedEvent));
    if (isTrustedEvent) {
      updateSearchWithQuery(buildUrlQueryString({
        name,
        value,
        existingUrlQueryString: getExistingQueryString(),
        event
      }));
      window.scrollTo(0, 0);
      setCompressionBannerDismiss(false);
    }
  };
  const onRemoveAppliedFilter = nextSearchCriteria => {
    const newQueryString = removeSelectedFiltersQuery(nextSearchCriteria, getExistingQueryString());
    captureFilterChange(newQueryString);
  };
  const onRemoveAllAppliedFilters = defaults => {
    const newQueryString = buildUrlQueryString({
      name: SortAndFiltersCustomEventTypes.CLEAR,
      value: {},
      existingUrlQueryString: getExistingQueryString(),
      stayOptionExperimentExposure: useStayOptionsGroupExposure,
      event: undefined,
      defaults
    });
    captureFilterChange(newQueryString);
  };
  const onSortDropdownClick = field => {
    if (field.name === 'sort') {
      track('HOT.SR:SortFilterControl', 'Search tools sort section dropdown clicked');
    }
  };
  const onSubmitFilters = React.useCallback(() => {
    const currentQueryString = urlQueryString === '' ? history.location.search : urlQueryString;
    const newQueryString = mergeQueryString(currentQueryString, {}, key => !key.startsWith('pwaDialog'));
    setTimeout(() => {
      updateSearchWithQuery(newQueryString);
      window.scrollTo(0, 0);
      setUrlQueryString('');
      setCompressionBannerDismiss(false);
    }, 200);
  }, [urlQueryString]);
  if (!analyticsSearch) {
    return null;
  }
  return React.createElement("div", {
    className: className
  }, React.createElement(LodgingSortAndFilters, Object.assign({
    onFieldChange: onFieldChange,
    onFieldClick: onSortDropdownClick,
    onRemoveAppliedFilter: onRemoveAppliedFilter,
    onRemoveAllAppliedFilters: onRemoveAllAppliedFilters,
    onSubmit: onSubmitFilters,
    onOpenDialog: onOpenDialog,
    view: view,
    inputs: adjustedInputs,
    loading: isSearchResultsLoading,
    filterSheetFooterInputs: adjustedFilterSheetFooterInputs,
    appliedFiltersSpacing: {
      margin: {
        blockend: 'three'
      }
    },
    isMapView: isMapView,
    isShoppingWithoutPackagePath: !(multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext),
    filterBarSpacing: filterBarSpacing,
    carouselEdgeItemsPadding: carouselEdgeItemsPadding
  }, multiTypeaheadProps(), {
    onSubmitFilterSearch: onSubmitFilterSearch
  })));
};
UniversalSortFilter.displayName = 'UniversalSortFilter';
export const UniversalSortFilterWrapper = ({
  view,
  className,
  onOpenDialog,
  isMapView,
  filterBarSpacing,
  carouselEdgeItemsPadding
}) => React.createElement(ErrorBoundary, {
  label: "hotel.search.universal-sort-filter"
}, React.createElement(UniversalSortFilter, {
  view: view,
  className: className,
  onOpenDialog: onOpenDialog,
  isMapView: isMapView,
  filterBarSpacing: filterBarSpacing,
  carouselEdgeItemsPadding: carouselEdgeItemsPadding
}));
UniversalSortFilterWrapper.displayName = 'UniversalSortFilterWrapper';