import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { HOUSE_RULES_GROUP } from 'src/common/constants/filters';
import { getFutureDates } from 'src/common/stores/adapters';
import { useStores } from 'src/common/stores/use-stores';
import { useExperiment } from '@shared-ui/experiment-context';
import { RefTarget } from '@shared-ui/ref-context';
import { LodgingSearchFormFeatures, LodgingSearchForm } from '@shared-ui/retail-search-tools-product';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
const getDate = (date, dateLocalFormat) => {
  const dateParts = date.split(/[-/]/);
  if (!dateParts[0] || !dateParts[1] || !dateParts[2]) return null;
  if (/^dd?\/mm?\/yyyy$/i.test(dateLocalFormat) && /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/.test(date)) {
    return {
      year: parseInt(dateParts[2], 10),
      month: parseInt(dateParts[1], 10),
      day: parseInt(dateParts[0], 10)
    };
  }
  if (/^mm?\/dd?\/yyyy$/i.test(dateLocalFormat) && /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(date)) {
    return {
      year: parseInt(dateParts[2], 10),
      month: parseInt(dateParts[0], 10),
      day: parseInt(dateParts[1], 10)
    };
  }
  // Try to parse the date assuming a yyyy M d format
  if (/^\d{4}[-/](0?[1-9]|1[0-2])[-/](0?[1-9]|[12][0-9]|3[01])$/.test(date)) {
    return {
      year: parseInt(dateParts[0], 10),
      month: parseInt(dateParts[1], 10),
      day: parseInt(dateParts[2], 10)
    };
  }
  return null;
};
const getFinalDateInputs = (inputs, inputStartDate, inputEndDate, isVrboPastDateSearch) => {
  var _a;
  if (inputs === null || inputs === void 0 ? void 0 : inputs.primarySearchCriteria.property) {
    if (inputStartDate && inputEndDate) {
      return Object.assign(Object.assign({}, inputs), {
        primarySearchCriteria: Object.assign(Object.assign({}, inputs === null || inputs === void 0 ? void 0 : inputs.primarySearchCriteria), {
          property: Object.assign(Object.assign({}, inputs.primarySearchCriteria.property), {
            dateRange: Object.assign(Object.assign({}, (_a = inputs === null || inputs === void 0 ? void 0 : inputs.primarySearchCriteria) === null || _a === void 0 ? void 0 : _a.property.dateRange), {
              checkInDate: inputStartDate,
              checkOutDate: inputEndDate
            })
          })
        }),
        secondaryCriteria: Object.assign(Object.assign({}, inputs.secondaryCriteria), {
          selections: []
        })
      });
    }
    if (isVrboPastDateSearch) {
      return Object.assign(Object.assign({}, inputs), {
        primarySearchCriteria: Object.assign(Object.assign({}, inputs === null || inputs === void 0 ? void 0 : inputs.primarySearchCriteria), {
          property: Object.assign(Object.assign({}, inputs.primarySearchCriteria.property), {
            dateRange: null
          })
        }),
        secondaryCriteria: Object.assign(Object.assign({}, inputs.secondaryCriteria), {
          selections: []
        })
      });
    }
    return Object.assign(Object.assign({}, inputs), {
      primarySearchCriteria: Object.assign(Object.assign({}, inputs === null || inputs === void 0 ? void 0 : inputs.primarySearchCriteria), {
        property: Object.assign({}, inputs.primarySearchCriteria.property),
        secondaryCriteria: Object.assign(Object.assign({}, inputs.secondaryCriteria), {
          selections: []
        })
      })
    });
  }
  return inputs;
};
export const SearchFormWrapping = ({
  handleSubmit,
  showDatePicker,
  toggleDatePicker,
  autoOpenPlayback,
  includePlayback,
  dateLocalFormat,
  queryParamStartDate,
  queryParamEndDate,
  isVrboPastDateSearch,
  queryParamLatLong,
  alternateDestination,
  dateFieldInitialFocus,
  redirectToSearchResultOnSubmit,
  autoSubmitOnFieldChange,
  queryParamDaysInFuture,
  queryParamStayLength,
  queryRooms
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
  const {
    page
  } = useStores();
  const history = useHistory();
  const inputs = useProductShoppingCriteria();
  const inputStartDate = getDate(queryParamStartDate, dateLocalFormat !== null && dateLocalFormat !== void 0 ? dateLocalFormat : '');
  const inputEndDate = getDate(queryParamEndDate, dateLocalFormat !== null && dateLocalFormat !== void 0 ? dateLocalFormat : '');
  const dateRangeInput = !inputStartDate && queryParamDaysInFuture ? getFutureDates(queryParamDaysInFuture, queryParamStayLength) : {
    checkInDate: inputStartDate,
    checkOutDate: inputEndDate
  };
  const sortFilter = (_a = inputs === null || inputs === void 0 ? void 0 : inputs.secondaryCriteria) === null || _a === void 0 ? void 0 : _a.selections;
  const finalInputs = (dateRangeInput === null || dateRangeInput === void 0 ? void 0 : dateRangeInput.checkInDate) && (dateRangeInput === null || dateRangeInput === void 0 ? void 0 : dateRangeInput.checkOutDate) || sortFilter ? getFinalDateInputs(inputs, (_b = dateRangeInput === null || dateRangeInput === void 0 ? void 0 : dateRangeInput.checkInDate) !== null && _b !== void 0 ? _b : null, (_c = dateRangeInput === null || dateRangeInput === void 0 ? void 0 : dateRangeInput.checkOutDate) !== null && _c !== void 0 ? _c : null, isVrboPastDateSearch) : inputs;
  const [skipQuery, setSkipQuery] = React.useState(false);
  const isAnyPetAllowed = (_e = (_d = inputs === null || inputs === void 0 ? void 0 : inputs.secondaryCriteria) === null || _d === void 0 ? void 0 : _d.selections) === null || _e === void 0 ? void 0 : _e.some(selection => selection.value === HOUSE_RULES_GROUP.PETS_ALLOWED);
  const [usedDialog, setUsedDialog] = React.useState(false);
  React.useEffect(() => {
    const pwaDialogParam = parse(history.location.search).pwaDialog;
    const usesDialog = Boolean(pwaDialogParam) || pwaDialogParam === '';
    if (history.action === 'PUSH' && usesDialog) {
      setUsedDialog(true);
    }
    if (history.action === 'POP' && usedDialog) {
      setSkipQuery(true);
      setUsedDialog(false);
    } else if (history.action === 'POP') {
      setSkipQuery(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.action]);
  React.useEffect(() => {
    /* istanbul ignore next */
    if (inputs && !skipQuery) {
      setSkipQuery(true);
    }
  }, [inputs, skipQuery]);
  const {
    exposure: vrboAlternateDestinationTypeaheadExposure,
    logExperiment: logVrboAlternateDestinationTypeaheadExperiment
  } = useExperiment('VRBO_Web_Alternative_Destinations_in_Typeahead_Lodging_SRP');
  const logDestinationFieldExperiments = React.useCallback(() => {
    try {
      logVrboAlternateDestinationTypeaheadExperiment(vrboAlternateDestinationTypeaheadExposure);
    } catch (_a) {
      /** no-op */
    }
  }, [vrboAlternateDestinationTypeaheadExposure, logVrboAlternateDestinationTypeaheadExperiment]);
  const vrboAlternateDestination = vrboAlternateDestinationTypeaheadExposure.bucket === 1;
  const alternateDestinationEnable = alternateDestination || vrboAlternateDestination;
  const testId = `${alternateDestinationEnable ? 'search-form-with-alternateDestination' : ''}`;
  const adapterFields = {
    /**
     * Enables popular filter feature on typeahead.
     * This is controlled via the `Popular_Filters_in_Typeahead` experiment which is evaluated in the Typeahead API.
     * The filters will be returned when the user enters at least 4 characters in the search input.
     */
    popularFilter: true,
    xPageId: page.pageId
  };
  const room = ((_g = (_f = finalInputs === null || finalInputs === void 0 ? void 0 : finalInputs.primarySearchCriteria) === null || _f === void 0 ? void 0 : _f.property) === null || _g === void 0 ? void 0 : _g.rooms) || [];
  const searchFormInputs = {
    propertySearchCriteriaInput: {
      primary: Object.assign(Object.assign({}, (_h = finalInputs === null || finalInputs === void 0 ? void 0 : finalInputs.primarySearchCriteria) === null || _h === void 0 ? void 0 : _h.property), {
        destination: Object.assign({}, (_k = (_j = finalInputs === null || finalInputs === void 0 ? void 0 : finalInputs.primarySearchCriteria) === null || _j === void 0 ? void 0 : _j.property) === null || _k === void 0 ? void 0 : _k.destination),
        rooms: [...(queryRooms.length > 0 ? queryRooms : room)]
      }),
      secondary: finalInputs === null || finalInputs === void 0 ? void 0 : finalInputs.secondaryCriteria
    }
  };
  return React.createElement(RefTarget, {
    name: "SearchForm",
    dataStid: testId
  }, React.createElement(LodgingSearchForm, {
    inputs: searchFormInputs !== null && searchFormInputs !== void 0 ? searchFormInputs : {},
    onSubmit: handleSubmit,
    includePlayback: includePlayback,
    autoOpenPlayback: autoOpenPlayback,
    skipQuery: skipQuery,
    showCalendar: showDatePicker,
    toggleCalendar: toggleDatePicker,
    autoSubmitOnFieldChange: autoSubmitOnFieldChange,
    isAnyPetAllowed: isAnyPetAllowed,
    updateLatLongOnSelection: alternateDestinationEnable,
    additionalAdapterFields: alternateDestinationEnable ? Object.assign({
      alternate: true,
      lastSearchLatLong: queryParamLatLong,
      drivable: ''
    }, adapterFields) : Object.assign({}, adapterFields),
    onOpenLocationField: logDestinationFieldExperiments,
    dateFieldInitialFocus: dateFieldInitialFocus,
    features: [LodgingSearchFormFeatures.PREFILL_PROPERTY_NAME],
    includeFlexibleCalendarContent: true,
    includeFlexibleDatesContent: true,
    redirectToSearchResultOnSubmit: redirectToSearchResultOnSubmit
  }));
};
SearchFormWrapping.displayName = 'SearchFormWrapping';