var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import { updateSearch } from 'bernie-core';
import { ErrorBoundary } from 'bernie-view';
import { EGDSMapInitialViewport } from '__generated__/api';
import { useDynamicSearchMapContext } from 'src/common/stores/contexts/map/dynamic-search-map-context';
import { SrpTemplate } from 'src/common/stores/page-layout/page-layout-store-utils';
import { isBexSplitView } from 'src/common/utils/is-bex-split-view';
import { isMobile, isTablet } from 'src/common/utils/is-mobile';
import { viewportMap } from 'src/common/utils/viewport-util';
import { useClickstreamAnalytics } from '@shared-ui/clickstream-analytics-context';
import { useExperiment } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { DynamicSearchMap } from '@shared-ui/lodging-property-search';
import { useViewportMap, ViewSizes } from '@shared-ui/viewport-context';
import { getPropertySearchInputWithSearchId } from 'stores/adapters';
import { isVrbo } from 'stores/context-store-utils';
import { preloadPdpHeroImage } from '../property-listing/preload-pdp-hero-image';
import { calculateNewMapInputs, checkPropertyClusterLabelKey } from './map-utils';
import { SearchPageName } from 'constants/pages';
export const computeInitialViewport = searchCriteria => {
  var _a, _b;
  const coordinates = (_b = (_a = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.latLong) === null || _a === void 0 ? void 0 : _a.split(',')) === null || _b === void 0 ? void 0 : _b.map(parseFloat);
  if ((coordinates === null || coordinates === void 0 ? void 0 : coordinates.length) === 2 && !(coordinates[0] === 0 && coordinates[1] === 0)) {
    return {
      center: coordinates,
      zoom: 10
    };
  }
};
const getProductLine = uisPageName => {
  var _a;
  return uisPageName === ((_a = SearchPageName.SA) === null || _a === void 0 ? void 0 : _a.valueOf()) ? 'Lodging' : 'Package';
};
export const DynamicSearchMapWrapper = inject('context', 'page', 'map', 'searchCriteria', 'multiItem', 'searchResults', 'experiment', 'analytics', 'currentHotel', 'analyticsSearch', 'pageLayout')(observer(({
  context,
  page,
  map,
  searchCriteria,
  multiItem,
  searchResults,
  experiment,
  analytics,
  currentHotel,
  cfg,
  clientPadding,
  onMapDialogClosed,
  filterComponent,
  fullscreenOptions,
  analyticsSearch,
  pageLayout,
  onInit,
  setViewportToApplyOnMapExpandChange,
  mapFocusHolderDisabled
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j;
  const history = useHistory();
  const location = useLocation();
  const l10n = useLocalization();
  const mapRef = React.useRef();
  const {
    highlightedPropertyId
  } = useDynamicSearchMapContext();
  const {
    exposure: akamaiSmartCroppingExposure
  } = useExperiment('Akamai_smart_cropping_for_PDP_image_gallery');
  const {
    exposure: mWebLargeImageExposure
  } = useExperiment('mWeb_larger_image_for_top_of_PDP_gallery');
  const {
    exposure: propertyDetailsQueryCsrExposure
  } = useExperiment('PropertyDetailsQuery_CSR');
  const isBEXGSplitViewExperimentEnabled = isBexSplitView(experiment, context);
  const {
    reset
  } = useClickstreamAnalytics();
  const setSavedFeature = map ? customPlace => map.setCustomPlace(customPlace) : undefined;
  const savedFeature = (_a = map === null || map === void 0 ? void 0 : map.customPlaces) === null || _a === void 0 ? void 0 : _a[0];
  const pageName = (page === null || page === void 0 ? void 0 : page.pageName) || SearchPageName.SA;
  const productLine = getProductLine(pageName);
  reset({
    data: {
      experience: {
        // eslint-disable-next-line camelcase
        page_product_line: productLine
      }
    },
    config: {
      isEgClickstream: true
    }
  });
  const [initialViewport] = React.useState(computeInitialViewport(searchCriteria));
  const viewport = useViewportMap(viewportMap);
  if (!context || !(searchResults === null || searchResults === void 0 ? void 0 : searchResults.searchCriteria) || !analyticsSearch) return null;
  const usePropertyListingQuery = !(multiItem === null || multiItem === void 0 ? void 0 : multiItem.isPackageShoppingPath());
  const calculateMapInputs = React.useCallback(() => {
    var _a, _b, _c, _d, _e;
    const paginationPreviousSubset = usePropertyListingQuery ? (_a = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.paginationSubSets) === null || _a === void 0 ? void 0 : _a.previousSubset : (_c = (_b = searchResults === null || searchResults === void 0 ? void 0 : searchResults.pagination) === null || _b === void 0 ? void 0 : _b.subSets) === null || _c === void 0 ? void 0 : _c.previousSubset;
    const paginationInput = {
      size: isVrbo(context) ? paginationPreviousSubset === null || paginationPreviousSubset === void 0 ? void 0 : paginationPreviousSubset.size : 100,
      startingIndex: ((_d = paginationPreviousSubset === null || paginationPreviousSubset === void 0 ? void 0 : paginationPreviousSubset.startingIndex) !== null && _d !== void 0 ? _d : 0) + ((_e = paginationPreviousSubset === null || paginationPreviousSubset === void 0 ? void 0 : paginationPreviousSubset.size) !== null && _e !== void 0 ? _e : 0)
    };
    const _f = getPropertySearchInputWithSearchId(context, analyticsSearch, searchResults.searchCriteria, multiItem === null || multiItem === void 0 ? void 0 : multiItem.multiItemContext, paginationInput),
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        context: bexApiContext
      } = _f,
      rest = __rest(_f, ["context"]);
    return rest;
  }, [context, multiItem, searchResults, searchCriteria, experiment]);
  const [mapInputs, setMapInputs] = React.useState(calculateMapInputs());
  React.useEffect(() => {
    autorun(() => {
      const newState = calculateMapInputs();
      setMapInputs(prevState => calculateNewMapInputs(prevState, newState));
    });
  }, [context, multiItem, searchResults, searchCriteria, experiment]);
  const updateViewportToApplyOnMapExpandChangeListener = React.useCallback(evt => {
    if (setViewportToApplyOnMapExpandChange) {
      setViewportToApplyOnMapExpandChange(evt.bounds);
    }
  }, [setViewportToApplyOnMapExpandChange]);
  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.addBoundChangeListener(updateViewportToApplyOnMapExpandChangeListener, 'updateViewportToApplyOnMapExpandChange');
    }
  }, [updateViewportToApplyOnMapExpandChangeListener]);
  const internalOnInit = React.useCallback(map => {
    mapRef.current = map;
    if (updateViewportToApplyOnMapExpandChangeListener) {
      map.addBoundChangeListener(updateViewportToApplyOnMapExpandChangeListener, 'updateViewportToApplyOnMapExpandChange');
    }
    if (onInit) {
      onInit(map);
    }
  }, []);
  const onDestinationChanged = destination => {
    var _a, _b, _c, _d, _e;
    const latLong = destination.coordinates ? `${destination.coordinates.latitude},${destination.coordinates.longitude}` : 'undefined,undefined';
    const mapBounds = destination.mapBounds ? [[(_a = destination.mapBounds[0]) === null || _a === void 0 ? void 0 : _a.latitude, (_b = destination.mapBounds[0]) === null || _b === void 0 ? void 0 : _b.longitude], [(_c = destination.mapBounds[1]) === null || _c === void 0 ? void 0 : _c.latitude, (_d = destination.mapBounds[1]) === null || _d === void 0 ? void 0 : _d.longitude]] : null;
    if (analytics === null || analytics === void 0 ? void 0 : analytics.clickTracker) {
      analytics.clickTracker('Module.Map.Search.Button', 'User clicking the map search button on PWA HSR Map', null, () => null);
    }
    analyticsSearch.withPublishingAnalyticsPayload(updateSearch, analytics, experiment)({
      history,
      location,
      newParams: Object.assign({
        regionId: '',
        destination: '',
        selected: '',
        poi: '',
        latLong,
        mapBounds,
        neighborhood: '',
        // eslint-disable-next-line camelcase
        multi_neighborhood_group: ''
      }, ((_e = searchResults.searchCriteria) === null || _e === void 0 ? void 0 : _e.categorySearch) ? {
        categorySearch: ''
      } : {})
    });
    window.scrollTo(0, 0);
  };
  const translate = React.useCallback((key, args) => checkPropertyClusterLabelKey(key, l10n, ...args), [l10n]);
  const egMapsConfig = React.useMemo(() => Object.assign(Object.assign(Object.assign({}, cfg), {
    i18n: {
      formatFunction: translate
    }
  }), {
    googleMaps: {
      gmapsAddConfig: {
        useStaticMap: false
      }
    }
  }), [cfg, translate]);
  const newFullscreenOptions = onMapDialogClosed ? {
    onClose: onMapDialogClosed,
    isDialogOpen: true,
    triggerRef: (_b = fullscreenOptions === null || fullscreenOptions === void 0 ? void 0 : fullscreenOptions.triggerRef) !== null && _b !== void 0 ? _b : null
  } : undefined;
  const isSplitViewTemplate = (pageLayout === null || pageLayout === void 0 ? void 0 : pageLayout.templateName) === SrpTemplate.SplitView;
  // this would work as temporal solution for random responses from search-api on split view template
  // used by vrbo brand
  const propertySearchResult = isSplitViewTemplate ? {
    __typename: 'PropertySearchResults',
    dynamicMap: searchResults === null || searchResults === void 0 ? void 0 : searchResults.dynamicMap,
    propertySearchListings: searchResults === null || searchResults === void 0 ? void 0 : searchResults.propertySearchListings
  } : undefined;
  // Moves the focus from the Map component back to the pagination button
  const onHandleKeyDownEntry = event => {
    if (event.shiftKey && event.key === 'Tab') {
      const paginationNextButtonRef = document.querySelectorAll('[data-stid="next-button"]');
      if (paginationNextButtonRef.length && paginationNextButtonRef[0]) {
        event.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        paginationNextButtonRef[0].focus();
      }
    }
  };
  // Moves the focus from the Map component back to the footer
  const onHandleKeyDownExit = event => {
    event.preventDefault();
    const backToFooter = document.getElementById('post-map-focus-footer');
    backToFooter.focus();
  };
  const onLodgingCardClickCallback = lodgingCard => {
    currentHotel === null || currentHotel === void 0 ? void 0 : currentHotel.setPropertyListingAsCurrentHotel(lodgingCard);
    const akamaiSmartCroppingVariant = akamaiSmartCroppingExposure.bucket;
    const mWebLargerImageVariant = mWebLargeImageExposure.bucket;
    const propertyDetailsQueryCsrVariant = propertyDetailsQueryCsrExposure.bucket;
    preloadPdpHeroImage(lodgingCard, {
      isMobile: isMobile(context),
      isTablet: isTablet(context)
    }, akamaiSmartCroppingVariant, mWebLargerImageVariant, propertyDetailsQueryCsrVariant);
  };
  const isLargeViewport = viewport === ViewSizes.LARGE || viewport === ViewSizes.EXTRA_LARGE;
  const hideOverfilteredToast = isBEXGSplitViewExperimentEnabled && isLargeViewport;
  const showToolbar = !isLargeViewport || !isSplitViewTemplate;
  React.useEffect(() => {
    var _a, _b, _c;
    if (setViewportToApplyOnMapExpandChange) {
      let viewport;
      const {
        initialViewport,
        bounds
      } = ((_a = propertySearchResult === null || propertySearchResult === void 0 ? void 0 : propertySearchResult.dynamicMap) === null || _a === void 0 ? void 0 : _a.map) || {};
      if (initialViewport === EGDSMapInitialViewport.BOUNDS && bounds) {
        const {
          northeast: {
            latitude: neLat,
            longitude: neLng
          },
          southwest: {
            latitude: swLat,
            longitude: swLng
          }
        } = bounds;
        viewport = {
          sw: [swLat, swLng],
          ne: [neLat, neLng]
        };
      } else if (initialViewport === EGDSMapInitialViewport.FIT_MARKERS) {
        viewport = (_c = (_b = propertySearchResult === null || propertySearchResult === void 0 ? void 0 : propertySearchResult.dynamicMap) === null || _b === void 0 ? void 0 : _b.map) === null || _c === void 0 ? void 0 : _c.markers.flatMap(f => {
          if (f.__typename === 'EGDSMapFeature') {
            return [[f.markerPosition.latitude, f.markerPosition.longitude]];
          }
          return [];
        });
      }
      if (viewport) {
        setViewportToApplyOnMapExpandChange(viewport);
      }
    }
  }, [setViewportToApplyOnMapExpandChange, (_c = propertySearchResult === null || propertySearchResult === void 0 ? void 0 : propertySearchResult.dynamicMap) === null || _c === void 0 ? void 0 : _c.map, (_e = (_d = propertySearchResult === null || propertySearchResult === void 0 ? void 0 : propertySearchResult.dynamicMap) === null || _d === void 0 ? void 0 : _d.map) === null || _e === void 0 ? void 0 : _e.bounds, (_g = (_f = propertySearchResult === null || propertySearchResult === void 0 ? void 0 : propertySearchResult.dynamicMap) === null || _f === void 0 ? void 0 : _f.map) === null || _g === void 0 ? void 0 : _g.markers, (_j = (_h = propertySearchResult === null || propertySearchResult === void 0 ? void 0 : propertySearchResult.dynamicMap) === null || _h === void 0 ? void 0 : _h.map) === null || _j === void 0 ? void 0 : _j.initialViewport]);
  return React.createElement(React.Fragment, null, !mapFocusHolderDisabled && React.createElement("div", {
    id: "map-focus-holder-entry",
    role: "button",
    tabIndex: 0,
    onKeyDown: onHandleKeyDownEntry,
    "aria-label": "Map"
  }), React.createElement(ErrorBoundary, {
    label: "search.map.dynamic-search-map"
  }, React.createElement(DynamicSearchMap, {
    showToolbar: showToolbar,
    hideOverfilteredToast: hideOverfilteredToast,
    propertySearchResult: propertySearchResult,
    inputs: mapInputs,
    selectedFeatureId: highlightedPropertyId,
    clientPadding: clientPadding,
    fullscreenOptions: newFullscreenOptions,
    egMapsConfig: egMapsConfig,
    filterComponent: filterComponent,
    onDestinationChanged: onDestinationChanged,
    onLodgingCardClickCallback: onLodgingCardClickCallback,
    initialViewport: initialViewport,
    height: "100%",
    // Turning off map loading spinner for VRBO on large viewports, loading bar in split-view will communicate loading state
    isPropertyListingLoading: isVrbo(context) && isLargeViewport ? undefined : searchResults === null || searchResults === void 0 ? void 0 : searchResults.isLoading,
    onInit: internalOnInit,
    setSavedFeature: setSavedFeature,
    savedFeature: savedFeature
  })), !mapFocusHolderDisabled && React.createElement("div", {
    id: "map-focus-holder-exit",
    role: "button",
    tabIndex: 0,
    onKeyDown: onHandleKeyDownExit,
    "aria-label": "Map"
  }));
}));
DynamicSearchMapWrapper.displayName = 'DynamicSearchMapWrapper';