import { useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { getSharedUIListingsInputsWithSearchId } from 'src/common/stores/adapters';
import { useStores } from 'src/common/stores/use-stores';
import { ResultsHeading as SharedUIResultsHeading } from '@shared-ui/lodging-property-listing';
import { useProductShoppingCriteria } from '@shared-ui/shopping-search-criteria-providers';
export const ResultsAccessibilityHeading = ({
  criteriaChanged,
  isHiddenFromScreenReader
}) => {
  var _a;
  const {
    context,
    experiment,
    searchCriteria,
    searchResults,
    analyticsSearch,
    pageLayout
  } = useStores();
  const location = useLocation();
  const listingsQueryInputs = useProductShoppingCriteria();
  const searchInputs = listingsQueryInputs ? getSharedUIListingsInputsWithSearchId(listingsQueryInputs, context, analyticsSearch, location, pageLayout, searchCriteria, experiment) : undefined;
  const [inputs, setInputs] = useState(searchInputs);
  useEffect(() => {
    if (criteriaChanged) {
      setInputs(searchInputs);
    }
  }, [criteriaChanged]);
  if (!context || !searchCriteria || !searchResults || !analyticsSearch) {
    return null;
  }
  const resultsHeading = (_a = searchResults.resultsHeading) === null || _a === void 0 ? void 0 : _a.split('|')[0];
  const resultData = {
    summary: {
      resultsHeading
    }
  };
  return inputs ? React.createElement(SharedUIResultsHeading, {
    data: resultData,
    propertySearchListingsPwa: searchResults === null || searchResults === void 0 ? void 0 : searchResults.propertySearchListings,
    inputs: inputs,
    isHiddenFromScreenReader: isHiddenFromScreenReader
  }) : null;
};
ResultsAccessibilityHeading.displayName = 'ResultsAccessibilityHeading';