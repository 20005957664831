import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { ErrorBoundary } from 'bernie-view';
import { Footer } from '@gc/global-components';
import { PlatformContext } from 'src/common/stores/contexts/platformContext';
import { useStores } from 'src/common/stores/use-stores';
import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from '@shared-ui/experiment-context';
import { useLocalization } from '@shared-ui/localization-context';
import { OneKeyOnboardingWrapper } from '@shared-ui/retail-loyalty-one-key-onboarding';
import { MultiContextProvider, SearchCriteriaContext } from 'stores/contexts';
import { AnimatedProgressIndicator } from 'components/common/animated-progress-indicator';
import { Header } from 'components/common/header/header';
import { Notification } from 'components/common/notification';
import { MesoScripts } from 'components/meso/internals/meso-scripts';
import { SimpleLazyLoadWrapper } from '../../common/lazy/simple-lazy-load-wrapper';
import { BodyRegion } from './body-region';
const MemoizedHeader = React.memo(Header);
const MemoizedFooter = React.memo(Footer);
// Reusable page structure with specific regions of content
const WrapperComponent = ({
  'data-stid': dataStid = 'master-page',
  inlineMargin,
  pageHeader,
  route,
  templateContent
}) => {
  const l10n = useLocalization();
  const {
    analytics,
    context,
    page,
    searchCriteria
  } = useStores();
  const LodgingShoppingTestExperiment = useExperiment('Lodging_Shopping_PWA_EGTNL_AA_Test');
  if (analytics && route && route.routeData) {
    analytics.setPageNamespace(route.routeData.rfrrNamespace || '');
    if (page && !page.pageName) {
      page.pageName = route.routeData.pageName || 'page.unknown';
    }
  }
  React.useEffect(() => {
    document.title = (page === null || page === void 0 ? void 0 : page.title) || l10n.formatText('common.defaultPageTitle');
  }, [page === null || page === void 0 ? void 0 : page.title, l10n]);
  React.useEffect(() => {
    LodgingShoppingTestExperiment.logExperiment(LodgingShoppingTestExperiment.exposure);
  }, [LodgingShoppingTestExperiment]);
  const contextPairs = [{
    context: PlatformContext,
    value: context
  }, {
    context: SearchCriteriaContext,
    value: searchCriteria
  }];
  return React.createElement(MultiContextProvider, {
    contextPairs: contextPairs
  }, React.createElement(ErrorBoundary, {
    label: "lodging.masterPage.egtnlServerStressTest"
  }, React.createElement(Experiment, {
    name: "stress_load_server_side_exposure_logging_in_shopping_pwa"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  }))), React.createElement("div", {
    "data-stid": dataStid
  }, React.createElement(Experiment, {
    name: "Lodging_Shopping_PWA_EGTNL_AA_Test"
  }, React.createElement(ExperimentControl, null), React.createElement(ExperimentVariant, {
    bucket: 1
  })), React.createElement(ErrorBoundary, {
    label: "lodging.masterPage.header"
  }, React.createElement(MemoizedHeader, null)), React.createElement(ErrorBoundary, {
    label: "onekey.onboarding.shopping-pwa"
  }, React.createElement(OneKeyOnboardingWrapper, null)), React.createElement("main", null, React.createElement(BodyRegion, {
    templateContent: templateContent,
    pageHeader: pageHeader,
    inlineMargin: inlineMargin
  })), React.createElement(ErrorBoundary, {
    label: "lodging.masterPage.footer"
  }, React.createElement(SimpleLazyLoadWrapper, null, React.createElement(MemoizedFooter, {
    skipSsr: true
  }))), React.createElement(AnimatedProgressIndicator, null), React.createElement(Notification, null)), React.createElement(ErrorBoundary, {
    label: "meso.scripts.errorboundary"
  }, React.createElement(MesoScripts, null)));
};
export const MasterPage = withRouter(WrapperComponent);